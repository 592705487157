export const Toggle = ({ title, checked, onChange }: any) => {
    return (
        <label className="relative inline-flex items-center cursor-pointer z-10">
            <input
                type="checkbox"
                className="sr-only peer outline-none"
                checked={checked}
                onChange={onChange}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-white select-none">
                {title}
            </span>
        </label>
    );
};
